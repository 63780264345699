<template>
  <div class="toastWrap" v-if="show" :class="showContent ?'fadein':'fadeout'">
      {{message}}
  </div>
</template>

<script>
export default {
    data(){
        return{}
    },
    props:{
    },
    methods:{
        
    },
    mounted(){
    }
}
</script>

<style lang="less" scoped>
.toastWrap{
    min-width:60px;
    min-height: 30px;
    max-width: 140px;
    padding: 4px 0.2rem;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 14px;
    color: #fff;
    background: rgba(0,0,0,.6);
    z-index: 999;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .fadein {
    animation: animate_in 0.25s;
  }
  .fadeout {
    animation: animate_out 0.25s;
    opacity: 0;
  }
  @keyframes animate_in {
    0% {
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  @keyframes animate_out {
    0% {
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
</style>