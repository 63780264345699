import Vue from 'vue'
import toastComponent from './Toast.vue'

// 返回一个 扩展实例构造器
const ToastConstructor = Vue.extend(toastComponent)

// 定义弹出组件的函数 接收两个参数，要显示的文本和时间

function showToast(message, duration = 2000) {
  // 实例化一个 toast.vue
  const toastDom = new ToastConstructor({
    el: document.createElement('div'),
    data() {
      return {
        message: message,
        show: true,
        showContent: true // 作用:在隐藏组件之前,显示隐藏动画
      }
    }
  })

  // 把实例化的toast.vue 添加到body里
  document.body.appendChild(toastDom.$el)

  // 过了 duration 时间后隐藏
//   setTimeout(() => {
//     toastDom.show = false
//   }, duration)
      // 提前 250ms 执行淡出动画(因为我们再css里面设置的隐藏动画持续是250ms)
      setTimeout(() => {toastDom.showContent = false} ,duration - 1250)
      // 过了 duration 时间后隐藏整个组件
      setTimeout(() => {toastDom.show = false} ,duration)
}

function registryToast() {
  // 将组件注册到 vue 的 原型链里面去，
  // 这样就可以在所有 vue 的实例里面使用 this.$toast()
  Vue.prototype.$toast = showToast
}
  
  
export default registryToast
