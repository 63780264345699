import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import(/* webpackChunkName: "home" */ '../views/Index.vue')
const NotFound = () => import(/* webpackChunkName: "notFound" */ '../components/NotFound.vue')
const List = () => import(/* webpackChunkName: "list caseList" */ '../components/List.vue')
const CaseList = () => import(/* webpackChunkName: "list caseList" */ '../views/CaseList.vue')
const Details = () => import(/* webpackChunkName: "details feedback" */ '../views/Details.vue')
const Feedback = () => import(/* webpackChunkName: "details feedback" */ '../views/Feedback.vue')
const Collect = () => import(/* webpackChunkName: "collect feedbackList" */ '../views/Collect.vue')
const FeedbackList = () => import(/* webpackChunkName: "collect feedbackList" */ '../views/FeedbackList.vue')
const FeedbackM = () => import(/* webpackChunkName: "feedbackM feedbackListM" */ '../views/FeedbackM.vue')
const FeedbackListM = () => import(/* webpackChunkName: "feedbackM feedbackListM" */ '../views/FeedbackListM.vue')
const Result = () => import(/* webpackChunkName: "result video" */ '../views/Result.vue')
const Video = () => import(/* webpackChunkName: "result video" */ '../views/Video.vue')
const SearchResult = () => import(/* webpackChunkName: "searchResult" */ '../views/SearchResult.vue')
const VideoCopy = () => import(/* webpackChunkName: "videoCopy" */ '../views/VideoCopy.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '*',
    redirect: '/NotFound'
  },
  {
    path: '/NotFound',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/caselist',
    name: 'CaseList',
    component: CaseList
  },
  {
    path: '/details',
    name: 'Details',
    component: Details
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  },
  {
    path: '/collect',
    name: 'Collect',
    component: Collect
  },
  {
    path: '/feedbackList',
    name: 'FeedbackList',
    component: FeedbackList
  },
  {
    path: '/feedbackM',
    name: 'FeedbackM',
    component: FeedbackM
  },
  {
    path: '/feedbackListM',
    name: 'FeedbackListM',
    component: FeedbackListM
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    path: '/searchResult',
    name: 'SearchResult',
    component: SearchResult
  },
  {
    path: '/videoCopy',
    name: 'VideoCopy',
    component: VideoCopy
  }
]
const router = new VueRouter({
  routes
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
  // chrome
  document.body.scrollTop = 0
    // firefox
  document.documentElement.scrollTop = 0
    // safari
  window.pageYOffset = 0
});
export default router
