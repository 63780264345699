import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mobile:false,
    navList:[]
  },
  mutations: {
    changeUserAgent(state,data){
      state.mobile = data;
      console.log(state.mobile)
    },
    addNavList(state,data){
      state.navList = data
    }
  },
  actions: {
  },
  modules: {
  }
})
