// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './plugins/element.js'
import './assets/css/global.css'
// import axios from 'axios'
/* 导入字体样式表 */
import './assets/fonts/iconfont.css'
// 导入封装好的toast组件
import registryToast from './toast/index.js'

Vue.use(registryToast)
// 导入mavonEditor
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)
// 导入VideoPlayer
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

// 使用VueLazyLoad
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad,{
    preLoad: 1.3,
    error:require('@/assets/images/error.jpg'),
    loading:require('@/assets/images/loading.gif'),
    attempt: 1
})

// 设置rem
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth
  if (htmlWidth >= 750) {
    htmlWidth = 750
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320
  }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`
}
window.onresize = setHtmlFontSize
setHtmlFontSize()

// 配置axios
axios.defaults.baseURL = 'https://xue.shoumai365.com/api'

//在request 拦截器中展示进度条 NProgress.start()
axios.interceptors.request.use(config => {
  // Nprogress.start()
  // 在请求传值的时候加上token
  // config.headers.Authorization = window.sessionStorage.getItem('token')
  config.timeout = 3000
  return config
})

//在request 拦截器中隐藏进度条 NProgress.done()
axios.interceptors.response.use(config => {
  // Nprogress.done()
  return config
})

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
