<template>
  <div>
    <!-- 右侧回到顶部 -->
    <div class="backTop" v-if="backBtnShow">
      <!-- <div class="btns">
        <div class="iconfont icon-gray_star"></div>
        <div class="btnText">我的收藏</div>
      </div>
      <div class="line"></div> -->
      <div class="btns" @click="toTop">
        <div class="iconfont icon-zhiding"></div>
        <div class="btnText">回到顶部</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      backBtnShow: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop
      scrolltop > 1000 ? (this.backBtnShow = true) : (this.backBtnShow = false)
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>

<style lang="less">
.backTop {
  padding: 49px 0;
  //   width: 87px;
  // height: 246px;
  background-color: #ffffff;
  border-radius: 43px;
  border: solid 1px #d2d2d2;
  position: fixed;
  right: 200px;
  bottom: 200px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .line {
    width: 63px;
    height: 1px;
    background-color: #666666;
    opacity: 0.33;
  }
  .btns {
    width: 100%;
    height: 60px;
    cursor: pointer;
    padding: 0 5px;
    .iconfont {
      width: 100%;
      font-size: 30px;
      text-align: center;
      color: #666666;
    }
    .btnText {
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #666666;
      text-align: center;
    }
  }
}
@media screen and(max-width:1680px) {
  .backTop{
    right: 0.2rem !important;
  }
}
@media screen and(max-width:1400px) {
  .backTop{
    display: none;
  }
}
@media screen and(max-width:750px) {
  .backTop{
    display: none;
  }
}
</style>
