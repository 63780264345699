/*
 * @desc 函数防抖
 * @param fnName 函数名
 * @param time 延迟执行毫秒数
 */
function VueDebounce(fnName, time) {
    let timeout = null;
    return function() {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        this[fnName]();
      }, time);
    };
  };
// 函数节流
function throttle(fn, interval = 800) {
    let timer = null;
    let firstTime = true;
    // let content = this;
    return function (...args) {
        if (firstTime) {
          // 第一次加载
          console.log(this)
            fn.apply(this, args);
            return firstTime = false;
        }
        if (timer) {
          // 定时器正在执行中，跳过
            return;
        }
        timer = setTimeout(() => {
            clearTimeout(timer);
            timer = null;
            fn.apply(this, args);
        }, interval);
    };
}

module.exports = {
	VueDebounce: VueDebounce,
	throttle: throttle,
}