<template>
  <div class="navWrap">
    <!-- pc端显示的顶部 -->
    <div class="main">
      <img class="logo" @click="navTo('Home')" src="@/assets/logo.png" alt="" />
      <div class="searchWrap">
        <!-- <input class="searchInput" type="text" placeholder="" value="" /> -->
        <el-autocomplete
          class="inline-input searchInput"
          v-model="state1"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          :trigger-on-focus="false"
          :debounce="0"
          @select="handleSelect"
        ></el-autocomplete>
        <div class="searchBtn" @click="searchClick">
          <span class="iconfont icon-sousuo"></span>
        </div>
      </div>
      <!-- <div class="userInfo">
        <div class="notice">
          <span class="iconfont icon-xiaoxi"></span>
        </div>
        <el-dropdown @command="handleCommand">
          <div class="user_avatar el-dropdown-link">
            <img class="avatar_img" src="@/assets/logo.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="Collect">收藏</el-dropdown-item>
            <el-dropdown-item command="Feedback">意见反馈</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="nick_name">小麦小麦小麦小麦小麦小麦小麦小麦</div>
      </div> -->
    </div>
    <!-- 移动端显示的顶部 -->
    <div class="m_main">
      <el-dropdown trigger="click" @command="golist">
        <div
          class="left_menu iconfont icon-leimupinleifenleileibie2 el-dropdown-link"
        ></div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in navList" :key="item.id"  :command="`?plate_id=${item.id}&name=${item.name}`">{{item.name}}</el-dropdown-item>
          <!-- <el-dropdown-item>电商进阶</el-dropdown-item>
          <el-dropdown-item>营销活动</el-dropdown-item>
          <el-dropdown-item>疑难解答</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <div class="searchWrap_m">
        <el-autocomplete
          class="inline-input searchInput_m"
          v-model="state1"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          :trigger-on-focus="false"
          :debounce="0"
          @select="handleSelect"
        ></el-autocomplete>
        <div class="searchBtn_m" @click="searchClick">
          <span class="iconfont icon-sousuo"></span>
        </div>
      </div>
      <!-- <el-dropdown trigger="click" @command="handleCommand">
        <div class="user iconfont icon-ren el-dropdown-link"></div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="Collect" icon="iconfont icon-shoucang"
            >收藏</el-dropdown-item
          >
          <el-dropdown-item command="FeedbackList" icon="iconfont icon-pen"
            >反馈</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { throttle } from '../assets/js/util.js'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  // 注入依赖
  inject: ['reload'],
  data() {
    return {
      state1: '',
      restaurants: [],
      inputTitle: '',
      navList_:[]
    }
  },
  props:{
    navlist:{
      type:Array,
      default:null
    }
  },
  computed: {
    ...mapState(['mobile', 'navList'])
  },
  mounted() {},
  methods: {
    // querySearch: throttle('testfunction'),
    // 搜索输入显示备选列表
    async querySearch(queryString, cb) {
      let { data: res } = await this.$http.get('/search-article', {
        params: { title: queryString,limit:5,page:1 }
      })
      if (res.state == 200) {
        res.data.data.forEach(item => {
          item.value = item.title
        })
      }
      // 调用 callback 返回建议列表的数据
      cb(res.data.data)
    },
    testfunction(){
      console.log(123)
    },
    // 监听搜索选择
    handleSelect(item) {
      console.log(item)
      this.goDetail(item.type, item.id,item.plate_id)
    },
    // 搜索按钮点击事件
    searchClick() {
      if (this.state1.trim() != '') {
        this.$router.push(`/searchResult?title=${this.state1}`)
        // 刷新页面
        this.state1 = ''
        this.reload()
      }else{
        this.state1 = ''
      }
    },
    // 跳转文章详情
    goDetail(type, id,plate_id) {
      if (type == 1) {
        this.$router.push(`/details?detailsId=${id}&plate_id=${plate_id}`)
      } else if (type == 2) {
        this.$router.push(`/video?detailsId=${id}&plate_id=${plate_id}`)
      }
      // 刷新页面
      this.reload()
    },
    navTo(url) {
      this.$router.push({ name: url })
    },
    golist(command) {
      // console.log(command)
      this.$router.push(`/caselist${command}`)
    },
    handleCommand(command) {
      console.log(command)
      this.$router.push({ name: command })
    }
  }
}
</script>
<style lang="less">
.m_main {
  display: none;
}
.navWrap {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-bottom: 1px solid #cdcdcd;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 999;
  .main {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    // background: rgba(0,0,0,.6);
    position: relative;
    .logo {
      width: 184px;
      float: left;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .searchWrap {
    width: 402px;
    height: 33px;
    position: absolute;
    border-radius: 40px;
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background: #eeeeee;
    .searchInput {
      width: 81%;
      float: left;
      height: 100%;
      padding-left: 20px;
      .el-input {
        height: 100%;
      }
      input {
        display: block;
        background: #eeeeee;
        border: none;
        outline: none;
        height: 100%;
      }
    }
    .searchBtn {
      width: 75px;
      height: 100%;
      background: #ffb11a;
      float: right;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 20px;
        color: #fff;
      }
    }
  }
  .userInfo {
    // width: 129px;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .notice {
      width: 27px;
      height: 31px;
      margin-right: 14px;
      cursor: pointer;

      .icon-xiaoxi {
        font-size: 27px;
        color: #2e333f;
      }
    }
    .user_avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 5px;
      cursor: pointer;
      float: left;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .nick_name {
      max-width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      float: left;
      font-size: 14px;
    }
    .el-dropdown-menu {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
@media screen and (max-width: 1200px) {
  .navWrap {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-bottom: 1px solid #cdcdcd;
    position: fixed;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 999;
    .main {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 767px) {
  .main {
    display: none;
  }
  .el-dropdown-menu {
    background: rgba(0, 0, 0, 0.6) !important;
    border: none !important;
  }
  .el-dropdown-menu__item {
    color: #fff !important;
    border: none !important;
    position: relative;
    padding: 0 0.2rem !important;
    .iconfont {
      font-size: 0.16rem;
    }
  }
  .el-popper[x-placement^='bottom'] .popper__arrow::after {
    border-bottom-color: rgba(0, 0, 0, 0.6) !important;
    // border: none !important;
  }
  .el-popper[x-placement^='bottom'] .popper__arrow {
    border-bottom-color: rgba(0, 0, 0, 0.6) !important;
  }
  .el-dropdown-menu__item::after {
    content: '';
    width: 50%;
    height: 1px;
    background: #a0a0a0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .m_main {
    width: 100%;
    height: 0.95rem;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .navWrap {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border-bottom: 0;
  }
  .navWrap .left_menu {
    width: 0.51rem;
    height: 0.51rem;
    // float: left;
    color: #ffb11a;
    font-size: 0.54rem;
  }
  .navWrap .user {
    width: 0.52rem;
    height: 0.52rem;
    font-size: 0.51rem;
    color: #ffb11a;
  }
  .navWrap .searchWrap_m {
    // width: 5.05rem;
    width: 6.5rem;
    height: 0.63rem;
    border-radius: 0.4rem;
    background: #fff;
    overflow: hidden;
    background: #eeeeee;
    .searchInput_m {
      width: 81%;
      float: left;
      height: 100%;
      border: none;
      outline: none;
      padding-left: 0.2rem;
      background: #eeeeee;
      .el-input {
        height: 100%;
      }
      input {
        display: block;
        background: #eeeeee;
        border: none;
        outline: none;
        height: 100%;
      }
    }
    .searchBtn_m {
      width: 0.75rem;
      height: 100%;
      background: #ffb11a;
      float: right;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 0.2rem;
        color: #fff;
      }
    }
  }
}
</style>
