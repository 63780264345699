<template>
  <div id="app">
    <NavBar />
    <router-view v-if="isRouterAlive" />
    <div style="clear:both;"></div>
    <Bottom />
    <BackTop v-if="!mobile" />
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import Bottom from '@/components/Bottom.vue'
import BackTop from '@/components/BackTop.vue'
import { mapMutations, mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['mobile'])
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  name: 'app',
  components: {
    NavBar,
    Bottom,
    BackTop
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    this.checkoutScreen();
  },
  methods: {
    ...mapMutations(['changeUserAgent']),
    checkoutScreen() {
      var UA = window.navigator.userAgent.toLowerCase()
      var isAndroid = UA.indexOf('android') > 0
      var isIOS = /iphone|ipad|ipod|ios/.test(UA)
      let bodyDom = document.getElementById('app')
      let w = window.innerWidth
      if ((isAndroid || isIOS) && w <= 769) {
        this.changeUserAgent(true)
        bodyDom.style.paddingTop = '40px'
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="less">
#app {
  padding-top: 60px;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // overflow: auto;
  // padding-bottom: 200px;
}
@media screen and (max-width: 750px) {
  #app {
    padding-top: 54px;
  }
}
</style>
