<template>
  <div class="bottom">
    <div class="bottomInner">
      <div class="row1">
        <div class="leftTitle">合作伙伴</div>
        <div class="rightImg" :style="{justifyContent:partnersImg.length<6?'flex-start':'space-between'}">
          <img v-for="item in partnersImg" :style="{marginRight:partnersImg.length<6?'10px':'0'}" :key="item.id" :src="item.obs_path" alt="" />
        </div>
      </div>
      <div class="row2">
        <div class="left">
          <div class="leftTitle">关于我们</div>
          <div class="aboutUsList">
            <div class="aboutUsItem" v-for="item in aboutUs" :key="item.id" @click="handle_click(item.link)">{{item.title}}</div>
          </div>
        </div>
        <div class="right">
          <div class="leftTitle">APP下载</div>
          <div class="qrcodeWrap">
            <div class="qrcode" v-for="item in  qrcode" :key="item.id">
              <img :src="item.image_file.obs_path" alt="" />
              <div class="qrcodeTitle">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div style="clear:both;"></div>
      </div>
      <!-- 手机端显示 -->
      <div class="mobile">
        <div class="m_title">合作伙伴</div>
        <div class="imgList" :style="{justifyContent:partnersImg.length<6?'flex-start':'space-between'}">
          <img v-for="item in partnersImg" :key="item.id" :src="item.obs_path" alt="" />
        </div>
        <div class="m_title">关于我们</div>
        <div class="m_content">
          <div class="m_text" v-for="item in aboutUs" :key="item.id" @click="handle_click(item.link)">{{item.title}}</div>
        </div>
        <div class="m_title">APP下载</div>
        <div class="qrcode">
          <!-- v-if="isAndroid" -->
          <img v-for="item in qrcode" :key="item.id" :src="item.image_file.obs_path"  alt="" />
          <!-- <img src="@/assets/images/qrcode2.png" v-if="isIOS" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['mobile'])
  },
  data() {
    return {
      isIOS:false,
      isAndroid:false,
      aboutUs:[], // 关于我们
      qrcode:[], // 二维码
      partnersImg:[] // 合作伙伴
    }
  },
  mounted(){
    // this.checkoutScreen()
  },
  created(){
    this.getQrCode();
    this.getBottomInfo();
    this.about_us_details();
    this.partners();
  },
  methods: {
    // checkoutScreen() {
    //   var UA = window.navigator.userAgent.toLowerCase()
    //   var isAndroid = UA.indexOf('android') > 0
    //   var isIOS = /iphone|ipad|ipod|ios/.test(UA)
    //   if (isAndroid) {
    //     this.isAndroid = true
    //   }else if (isIOS){
    //     this.isIOS = true
    //   }
    // },
    async getQrCode(){
      let {data:res} = await this.$http.get('/app-download',{params:{limit:4}});
      if(res.state == 200){
        this.qrcode = res.data
      }
      
    },
    async getBottomInfo(){
      // /api/about-us
      let {data:res} = await this.$http.get('/about-us',{params:{limit:10}});
      if(res.state == 200){
        this.aboutUs = res.data
      }
    
    },
    // /api
    async about_us_details(){
      let {data:res} = await this.$http.get('/about-us-details/10');
      console.log('/about-us-details/10')
      console.log(res)
    },
    async partners(){
      // /api/partners
      let {data:res} = await this.$http.get('/partners',{params:{limit:10}});
      if(res.state == 200){
        this.partnersImg = res.data
      }
      
    },
    handle_click(link){
      if(link){
        window.open(link, '_blank').location;
      }
    }
  }
}
</script>

<style lang="less">
.bottom {
  display: block;
  width: 100%;
  // min-height: 419px;
  background-color: #2e333f;
  margin-top: 100px;
  // position: fixed;
  // bottom: 0;
  // left: 50%;
  // transform: translateX(-50%);
}
.bottomInner {
  width: 1189px;
  height: 100%;
  margin: 0 auto;
  padding: 46px 0;
}
.row1 {
  width: 100%;
  height: 80px;
  .leftTitle {
    width: 20%;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansSC-Heavy;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #9b9b9b;
  }
  .rightImg {
    width: 70%;
    height: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    img {
      display: block;
      height: 80%;
    }
  }
}
.row2 {
  width: 100%;
  margin-top: 62px;
  //   height: 118px;
  .left {
    width: 60%;
    float: left;
    height: 100%;
    .leftTitle {
      width: 33%;
      height: 100%;
      float: left;
      font-family: SourceHanSansSC-Heavy;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: 0px;
      color: #9b9b9b;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .right {
    width: 40%;
    height: 100%;
    float: left;
  }
}
.aboutUsList {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10%;
  flex-wrap: wrap;
  .aboutUsItem {
    width: 33%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: SourceHanSansSC-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #9b9b9b;
    cursor: pointer;
  }
}
.right {
  width: 40%;
  height: 100%;
  .leftTitle {
    width: 100px;
    height: 100%;
    float: left;
    font-family: SourceHanSansSC-Heavy;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 0px;
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 47px;
  }
  .qrcodeWrap {
    width: 204px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .qrcode {
      width: 95px;
      height: 95px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .qrcodeTitle {
      width: 100%;
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      text-align: center;
      line-height: 30px;
      color: #8a8a8a;
    }
  }
}
.mobile {
    display: none;
}
@media screen and (max-width: 768px) {
  .row1,.row2{
    display: none;
  }
  .bottomInner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0.46rem 0.27rem;
  }
  .row1 {
    min-height: 80px;
    .leftTitle {
      width: 100%;
      height: 40px;
      float: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: SourceHanSansSC-Heavy;
      font-size: 0.3rem;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #9b9b9b;
    }
    .rightImg {
      width: 100%;
      height: 100%;
      float: left;
      display: flex;
      justify-content: space-around;
      align-items: center;
      // img {
      //   display: block;
      //   width: 10%;
      //   height: 100%;
      // }
    }
  }
  .mobile {
    width: 100%;
    display: block;
    .m_title {
      width: 100%;
      font-family: SourceHanSansSC-Bold;
      font-size: 0.3rem;
      line-height: 0.4rem;
      color: #9b9b9b;
      margin-top: 0.64rem;
      margin-bottom: 0.34rem;
    }
    .m_content {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      .m_text {
        // width: 32%;
        font-family: SourceHanSansSC-Regular;
        font-size: 0.24rem;
        line-height: 0.48rem;
        color: #9b9b9b;
        text-align: left;
        margin-right: 2%;
        cursor: pointer;
      }
      // .m_text:nth-child(3n) {
      //   width: 25%;
      // }
      // .m_text:nth-child(2n) {
      //   width: 40%;
      // }
    }
    .imgList {
      width: 100%;
      display: flex;
      justify-content:flex-start;
      align-items: center;
      // height: 0.8rem;
      flex-wrap: wrap;
      img {
        display: block;
        height: 0.8rem;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    .qrcode {
      width: 100%;
      height: 2.5rem;
      display: flex;
      justify-content: space-between;
      img{
        display: block;
        width: 2.51rem;
      height: 2.5rem;
      }
    }
  }
}
</style>
